const companyStats = [
    {
        id: 1,
        stat: '5K+',
        title: 'Happy Customers',
        desc: 'and they are becoming more happy',
    },
    {
        id: 2,
        stat: '205M+',
        title: 'Followers Gained',
        desc: 'and they never stop coming',
    },
    {
        id: 3,
        stat: '+150%',
        title: 'Increased Engagement',
        desc: 'your content is blowing',
    },
    {
        id: 4,
        stat: '+33%',
        title: 'Increased Leads',
        desc: 'the business keeps getting better',
    },
]

export default companyStats;